<template>
  <div>
    <div class="tip">登录</div>
    <div class="login">
      <p class="title">Pacific Foods</p>
      <div class="login_img"><img src="../../assets/image/logo.png" alt="" style="width:75px;height:75px"></div>
    </div>
    <div class="main">
        <van-form @submit="onSubmit">
          <van-field
                v-model="tel"
                center
                :rules="[
                  { required: true, message: 'Please enter your mobile phone number!' },
                  { validator:telValidator, message: '手機號格式錯誤！'}
                ]"
                type="tel"
                name="tel"
                placeholder="請輸入手機號(Mobile Phone)"
              >
            <template slot="left-icon">
              <span class="solts lefticon" @click="switchPasswordType" >
                  <img src="../../assets/image/icon_sj.png" alt="">
              </span>
            </template>
          </van-field>
          <van-field
            v-model="password"
            :type="passwordType"
            center
            name="password"
            :rules="[{ required: true, message: 'Please enter your password!' }]"
            placeholder="請輸入密碼(Password)"
          >
          <template slot="right-icon">
            <span class="solts" @click="switchPasswordType" >
                  <van-icon name="eye" v-if="passwordType==='text'"/>
                  <van-icon name="closed-eye" v-else />
            </span>
          </template>
          <template slot="left-icon">
            <span class="solts lefticon" @click="switchPasswordType" >
                <img src="../../assets/image/icon_mm.png" alt="">
            </span>
          </template>
          </van-field>
          <div style="margin: 16px;">
            <van-button style="font-size: 18px;"
              round
              block
              type="info"
              native-type="submit"
            >登錄</van-button>
          </div>
        </van-form>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { encrypt, decrypt } from '@/utils/jsencrypt'
export default {
  name: 'login',
  data() {
    return {
      tel: '',
      password: '',
      passwordType:'password'
    }
  },
  created() {
  },
  methods: {
    telValidator(val) {
        var reg = /^\d+$/;
        if(!reg.test(val)) {
          return false;
        }
      return true;
    },
    onSubmit() {
      const values = {
        tel:this.tel,
        password:this.password
      }
      this.$store.dispatch('Login', values).then(() => {
        this.$router.push('/category')
        sessionStorage.setItem('imgType',1)
      }).catch(error => {
        console.log(error)
      })
    },
    switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
  }
}
</script>

<style lang="scss" scoped>
.tip {
  width: 100%;
  height: 60px;
  line-height: 60px;
  color: #fff;
  text-align: center;
  position: fixed;
  top:5%;
}
.login {
  width: 100%;
  position: fixed;
  top:15%;
  text-align: center;
}
.login_img {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  object-fit:cover;
}
.title {
  text-align: center;
  color: #317213;
  margin-bottom: 30px;
}
.main {
		width: 80%;
		height: 320px;
		position: fixed;
		top: 40%;
    left: 10%;
}
.van-field {
  font-size: 18px;
}

.van-button__text {
  font-size: 18px;
}
.van-cell{
  font-size: 18px;
}
.van-button  {
    background: linear-gradient(90deg, #64B046, #AEE845);
    border: none;
}
.van-cell {
  border: 1px solid #68B346;
  border-radius: 20px;
  height: 80px;
  margin-bottom: 20px;
}
.van-icon-eye{
  margin-right: 20px;
}
.van-icon-closed-eye {
  margin-right: 20px;
}
.lefticon {
  width: 40px;
  height: 40px;
  margin: 0 10px;
}
.lefticon img {
  width: 40px;
  height: 40px;
}
</style>
